import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

import {
  computed, ref, set, watch,
} from '@vue/composition-api'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref({ ...props.event.value })
  const eventLocalUser = computed({
    get: () => (eventLocal.value ? store.getters['users/getUser'](eventLocal.value.userId) : null),
    set: value => set(eventLocal.value, 'userId', value ? value.id : null),
  })

  const resetEventLocal = () => {
    eventLocal.value = { ...props.event.value }
  }

  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------

  const toast = useToast()
  const toastPosition = 'bottom-right'

  const checkDate = (dateStr, dateEnd, eventId) => {
        if (!eventId && dateStr < new Date().toISOString()) {
            toast({
                component: ToastificationContent,
                position: toastPosition,
                props: {
                    title: 'Erreur date de création est antérieure à la date du jour',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            clearForm.value()
        } else if (eventId && dateEnd < new Date().toISOString()) {
            toast({
                component: ToastificationContent,
                position: toastPosition,
                props: {
                    title: 'Erreur date de fin est antérieure à la date du jour',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            clearForm.value()
        } else if (dateEnd < dateStr) {
            toast({
                component: ToastificationContent,
                position: toastPosition,
                props: {
                    title: 'Erreur date de fin antérieure à la date de début',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            clearForm.value()
        }
    }

  const cleanForm = () => {
    clearForm.value()
  }

  const onSubmit = () => {
    const newEventLocal = {
      id: eventLocal.value.id,
      start: dayjs(eventLocal.value.start).format(),
      end: dayjs(eventLocal.value.end).format(),
      userId: eventLocal.value.userId,
    }
    emit(props.event.value.id ? 'update-event' : 'add-event', newEventLocal)
    emit('update:is-event-handler-sidebar-active', false)
  }

  const users = computed(() => store.getters['calendar/usersAvailable'])

  return {
    eventLocal,
    eventLocalUser,
    resetEventLocal,
    users,
    checkDate,

    // UI
    onSubmit,
    cleanForm,
  }
}
