<template>
  <div class="sidebar-wrapper d-flex flex-column">
    <div id="sidebarEventHandler" :class="{ 'show': isEventHandlerSidebarActive }">
      <div class="sidebarInner">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <feather-icon class="ml-auto cursor-pointer" icon="XIcon" size="20"
            @click="$emit('update:is-event-handler-sidebar-active', false)" />
        </div>

        <!-- Body -->
        <validation-observer v-slot="{ handleSubmit, invalid }" ref="refFormObserver">

          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">

            <!-- Helpers -->
            <validation-provider v-slot="validationContext" name="users" rules="required">

              <b-form-group label="Intervenant" label-for="Title">
                <v-select v-model="eventLocalUser" :options="users"
                  :get-option-label="users => users.lastName + ' ' + users.firstName" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <validation-provider v-slot="validationContext" name="Start Date" rules="required">

              <b-form-group label="Date de début" label-for="start-date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.start" class="form-control" :config="flatPickrConfig" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider v-slot="validationContext" name="End Date" rules="required">

              <b-form-group label="Date de fin" label-for="end-date" :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.end" class="form-control" :config="flatPickrConfig" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" :disabled="invalid" type="submit"
                variant="primary" @click="checkDate(eventLocal.start, eventLocal.end, eventLocal.id);">
                {{ eventLocal.id ? 'Modifier' : 'Ajouter ' }}
              </b-button>
              <b-button v-if="eventLocal.id" v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="cursor-pointer ml-auto"
                :disabled="invalid" icon="TrashIcon" type="reset" variant="danger"
                @click="$emit('remove-event'); $emit('update:is-event-handler-sidebar-active', false); cleanForm();">
                Supprimer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BButton, BFormInvalidFeedback, VBToggle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { French as FrenchLocale } from 'flatpickr/dist/l10n/fr'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    VBToggle,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */

    const clearFormData = ref(null)
    const {
      eventLocal,
      eventLocalUser,
      resetEventLocal,
      users,
      checkDate,
      // UI
      onSubmit,
      cleanForm,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const flatPickrConfig = {
      dateFormat: 'Z',
      enableTime: true,
      weekNumbers: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      time_24hr: true,
      allowInput: true,
      locale: FrenchLocale,
      plugins: [new ConfirmDatePlugin()],
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      eventLocalUser,
      flatPickrConfig,
      onSubmit,
      cleanForm,
      users,
      // Form Validation
      resetForm,
      checkDate,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

#sidebarEventHandler {
  width: 325px;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  transform: translateX(110%);
  transition: all 0.3s ease, background 0s, border 0s, color 0s;
  z-index: 6;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }

  &.show {
    transform: translateX(0%);
    transition: all 0.3s ease, background 0s, border 0s, color 0s;
  }

  .sidebarInner {
    position: sticky;
    top: 62px;
  }
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
